import styled from "styled-components";
import React from "react";
import {ButtonUI, LinkUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {AdaptiveLinkCt} from "@components";


export const UseWalletSection = () => {

    return (
        <UseWalletSectionContainer>
            <UseWalletSectionTitle swichPoint={LAPTOP_MOBILE_POINTER}>Используйте Кошелёк для приёма оплаты за услуги и
                товары</UseWalletSectionTitle>
            <UseWalletSectionDescription>С нашим сервисом вы можете автоматизировать приём денег и выдачу чеков в
                статусе самозанятого</UseWalletSectionDescription>
            <WalletTryBtnLink to="https://pro.selfwork.ru">
                <WalletTryBtn>Попробовать</WalletTryBtn>
            </WalletTryBtnLink>
        </UseWalletSectionContainer>
    )

}

const UseWalletSectionContainer = styled.section`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    max-width: 820px;
    text-align: center;
    margin: 160px auto 0;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 100px auto 0;
        max-width: 100%;
    }

`;

const UseWalletSectionTitle = styled(TypographyUI.H2)`
    margin-bottom: 24px;
`;
const UseWalletSectionDescription = styled(TypographyUI.Body1Regular)`
    margin-bottom: 40px;
    color: var(--text-secondary);
`;

const WalletTryBtnLink = styled(AdaptiveLinkCt)`
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;

const WalletTryBtn = styled(ButtonUI)`
    width: 240px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;


