import styled from "styled-components";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {useWindowSize} from "@tools/hooks";
import {LinkSd} from "../../../ui/atoms/LinkUI/components/Root";

export const DownloadWalletSection = () => {

    const {width} = useWindowSize();

    return (
        <DownloadWalletSectionContainer>
        <DownloadWalletSectionItem style={{
            'width': '100%',
            'height': width && width > LAPTOP_MOBILE_POINTER ? '408px' : '100%',
            'background': 'var(--background-main-grey)',
        }}>
            <DownloadWalletSectionItemInfo
                style={{'maxWidth': width && width > LAPTOP_MOBILE_POINTER ? '48%' : '100%'}}>
                <TypographyUI.H2 className="title" swichPoint={LAPTOP_MOBILE_POINTER}>Создайте новый электронный Кошелёк</TypographyUI.H2>
                <TypographyUI.Body1Regular>Скачайте приложение и получайте деньги в один клик</TypographyUI.Body1Regular>
                <DownloadMarketLinks>
                    <LinkSd target="_blank" href={"https://redirect.appmetrica.yandex.com/serve/821280301215951660"}>
                        <StaticImage
                            src="../../../images/shared/app-store.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            width={152}
                            height={48}
                            alt="android-app"
                        />
                    </LinkSd>
                    <LinkSd target="_blank" href={"https://redirect.appmetrica.yandex.com/serve/965404397691520702"}>
                        <StaticImage
                            src="../../../images/shared/google-store.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            width={152}
                            height={48}
                            alt="ios-app"
                        />
                    </LinkSd>
                </DownloadMarketLinks>

            </DownloadWalletSectionItemInfo>

            <DownloadWalletSectionItemImage style={{
                'display': width && width > LAPTOP_MOBILE_POINTER ? 'block' : 'block',
                'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative',
                'right': width && width > LAPTOP_MOBILE_POINTER ? '95px' : '0',
                'width': width && width > LAPTOP_MOBILE_POINTER ? '442px' : 'auto',
                'top': width && width > LAPTOP_MOBILE_POINTER ? '50px' : '0'
            }}>
                <StaticImage
                    src="../../../images/elektronniy-koshelek/DownloadWalletSectionImage.png"
                    loading="eager"
                    placeholder="none"
                    formats={["auto", "webp"]}
                    layout="constrained"
                    objectFit="contain"
                    alt=""
                />
            </DownloadWalletSectionItemImage>
        </DownloadWalletSectionItem>
        </DownloadWalletSectionContainer>
    )
};

const DownloadWalletSectionContainer = styled.section`
    margin: 0px 0 100px;
    @media (max-width: 609px) {
        margin: 80px -19px 60px;
    }
`;

const DownloadMarketLinks = styled.div`
    display: flex;
    gap: 16px;
`;

const DownloadWalletSectionItem = styled.div`
    padding-top: 40px;
    padding-left: 95px;
    padding-right: 95px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    @media (max-width: 609px) {
        border-radius: 0px;
        padding-left: 20px;
        padding-right: 20px;
    }
`;

const DownloadWalletSectionItemInfo = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    gap: 32px;
`;

const DownloadWalletSectionItemImage = styled.div`
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 300px;
        margin: 0 auto;
        padding: 20px 0 0;
    }

`;
