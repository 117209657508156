import styled from "styled-components";
import React from "react";
import {ButtonUI, LinkUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER, walletLink} from "@tools/constants";
import {StaticImage} from "gatsby-plugin-image";


export const SoonCardsSection = () => {

    return (
        <SoonCardsSectionContainer>
            <SoonCardsSectionInfo>
                <SoonCardsSectionInfoTitle  swichPoint={LAPTOP_MOBILE_POINTER}>
                    Скоро — платежные карты
                </SoonCardsSectionInfoTitle>
                <SoonCardsSectionInfoDescription>
                    С платежной картой Сам.Кард вы забудете о переводах и комиссиях — приложите пластиковую или электронную карту к терминалу и оплатите покупку балансом Кошелька
                </SoonCardsSectionInfoDescription>
            </SoonCardsSectionInfo>

            <SoonCardsSectionImage>
                <StaticImage
                    src="../../../images/elektronniy-koshelek/SoonCardsSectionImage.png"
                    loading="eager"
                    placeholder="none"
                    formats={["auto", "webp"]}
                    layout="constrained"
                    objectFit="contain"
                    alt=""
                />
            </SoonCardsSectionImage>
        </SoonCardsSectionContainer>

)

}

const SoonCardsSectionContainer = styled.section`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: relative;
    margin: 80px auto 160px;
}
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 60px auto 40px;
        flex-direction: column;
    }

`;

const SoonCardsSectionInfo = styled.div`
    max-width: 490px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 100%;
        text-align: center;
    }
}
`;

const SoonCardsSectionInfoTitle = styled(TypographyUI.H2)`
    margin-bottom: 24px;
`;
const SoonCardsSectionInfoDescription = styled(TypographyUI.Body1Regular)`
    margin-bottom: 24px;
    color: var(--text-secondary);
`;

const SoonCardsSectionImage = styled.div`
    width: 486px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;


