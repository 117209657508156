import React from "react";
import styled from "styled-components";

import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {TypographyUI} from "@ui";
import srcImg1 from "@images/elektronniy-koshelek/AdvantagesWalletSectionImage1.png";
import srcImg2 from "@images/elektronniy-koshelek/AdvantagesWalletSectionImage2.png";
import srcImg3 from "@images/elektronniy-koshelek/AdvantagesWalletSectionImage3.png";
import srcImg4 from "@images/elektronniy-koshelek/AdvantagesWalletSectionImage4.png";

export const AdvantagesWalletSection = () => (
    <SectionBoxSd>
        <TextBoxSd>
            <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER}>
                Преимущества Кошелька
            </TypographyUI.H2>
        </TextBoxSd>
        <SectionItems>
            <SectionItem>
                <SectionItemContent>
                    <TypographyUI.H4Bold className="title">Моментальный приём и вывод денег</TypographyUI.H4Bold>
                    <TypographyUI.Body1Regular className="description">Получайте деньги по ссылке, QR-коду или через онлайн-эквайринг и выводите средства на карту или другие электронные кошельки в России</TypographyUI.Body1Regular>
                </SectionItemContent>
                <SectionItemImage>
                    <img src={srcImg1} alt={''}/>
                </SectionItemImage>
            </SectionItem>

            <SectionItem>
                <SectionItemContent>
                    <TypographyUI.H4Bold className="title">Бесплатные сервисы</TypographyUI.H4Bold>
                    <TypographyUI.Body1Regular className="description">Вы платите только комиссию в размере 3,5% за вывод денежных средств</TypographyUI.Body1Regular>
                </SectionItemContent>
                <SectionItemImage>
                    <img src={srcImg2} alt={''}/>
                </SectionItemImage>
            </SectionItem>

            <SectionItem>
                <SectionItemContent>
                    <TypographyUI.H4Bold className="title">Понятная история операций</TypographyUI.H4Bold>
                    <TypographyUI.Body1Regular className="description">Все пополнения баланса электронного кошелька и вывод средств хранятся в вашем личном кабинете</TypographyUI.Body1Regular>
                </SectionItemContent>
                <SectionItemImage>
                    <img src={srcImg3} alt={''}/>
                </SectionItemImage>
            </SectionItem>

            <SectionItem>
                <SectionItemContent>
                    <TypographyUI.H4Bold className="title">Платежи в Телеграм</TypographyUI.H4Bold>
                    <TypographyUI.Body1Regular className="description">Вы также можете настроить автоматический приём денег с помощью Телеграм-бота</TypographyUI.Body1Regular>
                </SectionItemContent>
                <SectionItemImage>
                    <img src={srcImg4} alt={''}/>
                </SectionItemImage>
            </SectionItem>

        </SectionItems>
    </SectionBoxSd>
);

const SectionBoxSd = styled.section`
    display: flex;
    transition: all 0.3s;
    padding-top: 160px;
    flex-direction: row;
    gap: 71px;
    padding-bottom: 120px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        padding-top: 80px;
        padding-bottom: 60px;
        flex-direction: column;
        gap: 0;
    }
`;

const TextBoxSd = styled.div`
    max-width: 417px;
    width: 100%;
    margin-bottom: 56px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 100%;
        margin-bottom: 40px;
        gap: 16px;
    }
`;

const SectionItems = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
`;


const SectionItem = styled.div`
    background: var(--background-main-grey);
    padding: 24px 37px 24px 28px;
    border-radius: 20px;
    display: flex;
    width: 655px;
    height: 172px;
    justify-content: space-between;
    align-items: center;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
        height: 100%;
        flex-direction: column-reverse;
        gap: 16px;
        align-items: flex-start;
        padding: 24px 28px;
    }
`;

const SectionItemContent = styled.div`
    max-width: 414px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 100%;
    }

    .title {
        margin-bottom: 16px;
    }

    .description {
        color: var(--text-secondary);
    }
`;

const SectionItemImage = styled.div`
    img {
        width: 116px;
        @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
            width: 60px !important;
        }
    }
`;
