import {ButtonUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import React from "react";
import styled from "styled-components";

import num1Img from "@images/elektronniy-koshelek/OpenWalletSectionImage1.svg";
import num2Img from "@images/elektronniy-koshelek/OpenWalletSectionImage2.svg";
import num3Img from "@images/elektronniy-koshelek/OpenWalletSectionImage3.svg";
import {AdaptiveLinkCt} from "@components";

export const OpenWalletSection = () => {

    return (
        <OpenWalletSectionContainer>
            <OpenWalletSectionTitle swichPoint={LAPTOP_MOBILE_POINTER}>
                Мгновенное открытие кошелька
            </OpenWalletSectionTitle>

            <OpenWalletSectionItems>
                <OpenWalletSectionItem>
                    <OpenWalletSectionItemImg
                        src={num1Img}
                        alt="num-1"
                    />
                    <OpenWalletSectionItemTitle>
                        Зарегистрируйтесь в Кошельке
                    </OpenWalletSectionItemTitle>
                    <OpenWalletSectionItemDescription>
                        Вам потребуется номер телефона и код из СМС
                    </OpenWalletSectionItemDescription>
                </OpenWalletSectionItem>

                <OpenWalletSectionItem>
                    <OpenWalletSectionItemImg
                        src={num2Img}
                        alt="num-2"
                    />
                    <OpenWalletSectionItemTitle>
                        Пройдите идентификацию
                    </OpenWalletSectionItemTitle>
                    <OpenWalletSectionItemDescription>
                        Укажите паспортные данные, чтобы получать и выводить деньги
                    </OpenWalletSectionItemDescription>
                </OpenWalletSectionItem>

                <OpenWalletSectionItem>
                    <OpenWalletSectionItemImg
                        src={num3Img}
                        alt="num-3"
                    />
                    <OpenWalletSectionItemTitle>
                        Используйте Кошелёк для некоммерческих целей
                    </OpenWalletSectionItemTitle>
                    <OpenWalletSectionItemDescription>
                        Чтобы принимать деньги за услуги или товары, откройте самозанятость и подтвердите статус через ФНС
                    </OpenWalletSectionItemDescription>
                </OpenWalletSectionItem>

            </OpenWalletSectionItems>

            <OpenWalletBtnContainer>
                <OpenWalletBtnLink to="https://pro.selfwork.ru">
                    <OpenWalletBtn>Открыть кошелёк</OpenWalletBtn>
                </OpenWalletBtnLink>
            </OpenWalletBtnContainer>

        </OpenWalletSectionContainer>
    )
};

const OpenWalletSectionContainer = styled.section`
    margin: 160px 0 160px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 100px 0 100px;
    }
`;

const OpenWalletSectionTitle = styled(TypographyUI.H2)`
    text-align: center;
    margin-bottom: 75px;
`

const OpenWalletSectionItems = styled.div`
    display: flex;
    gap: 32px;

    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        flex-direction: column;
    }
`;

const OpenWalletSectionItem = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    max-width: 360px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 100%;
    }
`;

const OpenWalletSectionItemImg = styled.img`
    width: 72px;
    height: 72px;
    margin-bottom: 32px;
`;

const OpenWalletSectionItemTitle = styled(TypographyUI.H4Bold)`
    padding-bottom: 12px;
`;

const OpenWalletSectionItemDescription = styled(TypographyUI.Body1Regular)`
    color: var(--text-secondary);
`;

const OpenWalletBtnContainer = styled.div`
    margin: 80px auto 0;
    display: flex;
    justify-content: center;
`;

const OpenWalletBtnLink = styled(AdaptiveLinkCt)`
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;

const OpenWalletBtn = styled(ButtonUI)`
    width: 240px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;
